@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Black.eot');
    src: url('Nunito-Black.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Black.woff2') format('woff2'),
    url('Nunito-Black.woff') format('woff'),
    url('Nunito-Black.ttf') format('truetype'),
    url('Nunito-Black.svg#Nunito-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Bold.eot');
    src: url('Nunito-Bold.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Bold.woff2') format('woff2'),
    url('Nunito-Bold.woff') format('woff'),
    url('Nunito-Bold.ttf') format('truetype'),
    url('Nunito-Bold.svg#Nunito-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-BlackItalic.eot');
    src: url('Nunito-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-BlackItalic.woff2') format('woff2'),
    url('Nunito-BlackItalic.woff') format('woff'),
    url('Nunito-BlackItalic.ttf') format('truetype'),
    url('Nunito-BlackItalic.svg#Nunito-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-ExtraLight.eot');
    src: url('Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('Nunito-ExtraLight.woff2') format('woff2'),
    url('Nunito-ExtraLight.woff') format('woff'),
    url('Nunito-ExtraLight.ttf') format('truetype'),
    url('Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-ExtraLightItalic.eot');
    src: url('Nunito-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-ExtraLightItalic.woff2') format('woff2'),
    url('Nunito-ExtraLightItalic.woff') format('woff'),
    url('Nunito-ExtraLightItalic.ttf') format('truetype'),
    url('Nunito-ExtraLightItalic.svg#Nunito-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Italic.eot');
    src: url('Nunito-Italic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Italic.woff2') format('woff2'),
    url('Nunito-Italic.woff') format('woff'),
    url('Nunito-Italic.ttf') format('truetype'),
    url('Nunito-Italic.svg#Nunito-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-ExtraBoldItalic.eot');
    src: url('Nunito-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-ExtraBoldItalic.woff2') format('woff2'),
    url('Nunito-ExtraBoldItalic.woff') format('woff'),
    url('Nunito-ExtraBoldItalic.ttf') format('truetype'),
    url('Nunito-ExtraBoldItalic.svg#Nunito-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-BoldItalic.eot');
    src: url('Nunito-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-BoldItalic.woff2') format('woff2'),
    url('Nunito-BoldItalic.woff') format('woff'),
    url('Nunito-BoldItalic.ttf') format('truetype'),
    url('Nunito-BoldItalic.svg#Nunito-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-ExtraBold.eot');
    src: url('Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Nunito-ExtraBold.woff2') format('woff2'),
    url('Nunito-ExtraBold.woff') format('woff'),
    url('Nunito-ExtraBold.ttf') format('truetype'),
    url('Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-LightItalic.eot');
    src: url('Nunito-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-LightItalic.woff2') format('woff2'),
    url('Nunito-LightItalic.woff') format('woff'),
    url('Nunito-LightItalic.ttf') format('truetype'),
    url('Nunito-LightItalic.svg#Nunito-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-SemiBoldItalic.eot');
    src: url('Nunito-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Nunito-SemiBoldItalic.woff2') format('woff2'),
    url('Nunito-SemiBoldItalic.woff') format('woff'),
    url('Nunito-SemiBoldItalic.ttf') format('truetype'),
    url('Nunito-SemiBoldItalic.svg#Nunito-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Light.eot');
    src: url('Nunito-Light.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Light.woff2') format('woff2'),
    url('Nunito-Light.woff') format('woff'),
    url('Nunito-Light.ttf') format('truetype'),
    url('Nunito-Light.svg#Nunito-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Regular.eot');
    src: url('Nunito-Regular.eot?#iefix') format('embedded-opentype'),
    url('Nunito-Regular.woff2') format('woff2'),
    url('Nunito-Regular.woff') format('woff'),
    url('Nunito-Regular.ttf') format('truetype'),
    url('Nunito-Regular.svg#Nunito-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-SemiBold.eot');
    src: url('Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Nunito-SemiBold.woff2') format('woff2'),
    url('Nunito-SemiBold.woff') format('woff'),
    url('Nunito-SemiBold.ttf') format('truetype'),
    url('Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

