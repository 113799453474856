/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'assets/css/fonts/fonts';

/* You can add global styles to this file, and also import other style files */
body {
	padding: 0;
	font-family: 'Nunito', sans-serif;
	font-size: 1rem;
	margin: 100px 0 0;

	--blue: #345190;
	--blue-medium: #496fc0;
	--blue-ligth-normal: #6293fc;
	--blue-ligth: #56ccf2;
	--blue-extra-ligth: #d2e1ff;
	--white: #ffffff;
	--black: #000000;

	--red: #eb5757;
	--orange: #f2994a;
	--yellow: #f2c94c;

	--green: #1ec589;
	--green-medium: #6fcf97;

	--natural: #f5f5f5;

	--gray: #4f4f4f;
	--gray-normal: #828282;
	--gray-light: #d8d8d8;
	--gray-extra-light: #e8e8e8;

	--heightDefaultConsult: 124px;
	--heightDefaultExams: 195px;

	--underweight: rgba(85, 179, 247, 0.11);
	--normal: rgba(30, 197, 137, 0.11);
	--overweight: rgba(252, 139, 63, 0.21);
	--obese: rgba(252, 139, 63, 0.31);
	--extreme-obese: rgba(220, 53, 69, 0.11);

	*:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--black);
	margin: 0;
}

h1,
h2 {
	font-style: normal;
	font-weight: bold;
	font-size: 56px;
	line-height: 120%;
}

h3 {
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 120%;
	letter-spacing: 0.02em;
}

h4 {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 120%;
	letter-spacing: 0.02em;
}

h5 {
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	letter-spacing: 0.01em;
}

h6 {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.01em;
}

p {
	margin: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 160%;
}

a {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 24px;
	color: var(--black);
}

figure {
	margin: 0;
}

button {
	background: transparent;
	border: 0;
}

.page {
	position: relative;
	min-height: 480px;
}

.container {
	max-width: 100%;
	width: 100%;

	&.sidebar {
		padding-left: 15px;
	}

	@media (min-width: 1025px) {
		&.sidebar {
			padding-left: 90px;
		}
	}

	@media (min-width: 1560px) {
		max-width: 1440px;

		&.sidebar {
			padding-left: 0;
		}
	}
}

.form-group {
	margin-bottom: 25px;
	position: relative;

	input,
	select {
		font-style: normal;
		font-weight: 200;
		width: 100%;
		border-radius: 5px;
		border: 1px solid var(--gray);
		outline: none;
		height: 56px;
		line-height: 1;
		font-size: 14px;
		padding: 12px 0 10px 15px;

		&:focus {
			box-shadow: none !important;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	.default {
		input,
		select {
			font-style: normal;
			font-weight: 200;
			border-radius: 5px;
			outline: none;
			line-height: 1;
			font-size: 12px;
			padding: 12px 0 10px 15px;
			border: 1px solid var(--gray-light);
			min-width: 100px;
			width: auto;
			height: 30px;

			&:focus {
				box-shadow: none !important;
			}
		}

		select {
			padding: 6px 22px 6px 6px;
		}
	}

	label {
		width: 100%;
	}

	.control {
		&-label {
			font-style: normal;
			font-weight: bold;
			background: var(--white);
			position: absolute;
			left: 10px;
			top: -7px;
			padding: 0 9px;
			outline: none;
			text-transform: uppercase;
			font-size: 10px;
			color: var(--gray-dark);
			z-index: 1;
		}

		&-error {
			color: var(--red);
			font-size: 12px;
			position: absolute;
			left: 12px;
			bottom: -12px;
		}

		@media (min-width: 560px) {
			&-label {
				font-size: 12px;
			}
		}
	}
}

.input-check {
	position: relative;
	padding-left: 33px;
	margin-bottom: 10px;

	label {
		font-size: 16px;
		line-height: 16px;

		letter-spacing: 0.4px;
		color: var(--gray);
		margin-bottom: 0;

		&:hover {
			cursor: pointer;
		}
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:disabled {
			& ~ div {
				opacity: 0.3;
				cursor: no-drop;
				& ~ span {
					opacity: 0.3;
					cursor: no-drop;
				}
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 1px;
		left: 0;
		height: 22px;
		width: 22px;
		border-radius: 2px;
		border: 2px solid var(--gray);

		&:after {
			content: '';
			position: absolute;
			display: none;

			left: 5px;
			top: 2px;
			width: 7px;
			height: 12px;
			border: solid var(--gray);
			border-width: 0 2px 2px 0;

			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}

	input[type='radio'] {
		& ~ .checkmark {
			border-radius: 50%;
		}
	}

	.control-error {
		padding: 0;
		color: var(--red);
		font-size: 12px;
		display: block;
	}

	&:hover input ~ .checkmark {
		background-color: var(--white);
	}

	input:checked ~ .checkmark:after {
		display: block;
	}

	/** hover checkbox **/
	&:hover {
		cursor: pointer;
	}
}

::placeholder {
	color: #bdbdbd;
	opacity: 1;
}
:-ms-input-placeholder {
	color: #bdbdbd;
}
::-ms-input-placeholder {
	color: #bdbdbd;
}

.show-pass {
	position: absolute;
	right: 0;
	top: 14px;
	border: 0;
	background: transparent;

	&:after {
		content: '';
		position: absolute;
		left: 15px;
		top: 1px;
		width: 3px;
		height: 26px;
		background: #828282;
		transform: rotate(45deg);
	}

	&.active {
		&:after {
			content: none;
		}
	}

	figure {
		margin: 0;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: black !important;
	-webkit-box-shadow: 0 0 0 30px #ffffff inset;
	transition: background-color 5000s ease-in-out 0s;
}

.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: var(--blue-medium);
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--white);

	&:hover {
		color: var(--white);
	}

	&:focus {
		box-shadow: none;
	}

	&.outline {
		background: var(--white);
		border: 1px solid var(--blue-medium);
		border-radius: 6px;
		color: var(--blue-medium);

		&:hover {
			color: var(--blue-medium);
		}
	}

	&.disabled {
		background: var(--gray-light);
		border-color: var(--gray-light);
		color: var(--white);
		cursor: no-drop;
	}

	&-xs {
		width: 106px;
		height: 24px;
		font-size: 12px;
	}

	&-small {
		border-radius: 8px;
		width: 200px;
		height: 32px;
		font-size: 12px;
	}

	&-medium {
		width: 200px;
		height: 48px;
		border-radius: 8px;
		font-size: 14px;
	}
}

.empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	height: 50vh;
	grid-gap: 25px;

	h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		letter-spacing: 0.02em;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: #828282;
		max-width: 380px;
	}

	&-small {
		height: auto;

		img {
			width: 50px;
		}

		h3 {
			font-size: 16px;
		}
	}
}

.toast {
	$this: &;

	&-container {
		.ngx-toastr {
			border-left: 5px solid var(--blue-medium);
			//border-radius: 0 3px 3px 0 !important;
			color: var(--black) !important;
			background: var(--white);
			box-shadow: 0 2px 13px rgba(0, 0, 0, 0.27) !important;

			&.toast-info {
				border-color: var(--yellow);
			}
			&.toast-success {
				border-color: var(--green);
			}
			&.toast-error {
				border-color: var(--red);
			}

			.toast-title {
				font-weight: 600;
				color: var(--gray);
			}

			.toast-message {
				font-weight: 300;
				color: var(--gray);
			}

			button {
				color: var(--gray);
			}
		}
	}

	&-progress {
		background-color: var(--gray-light) !important;
	}
}

.wrapper-search {
	display: flex;
	align-items: center;
	grid-gap: 15px;
	padding-bottom: 10px;

	p {
		margin: 0;
	}

	::ng-deep .form-group {
		margin-bottom: 0;
	}
}

.custom-scroll {
	&::-webkit-scrollbar {
		width: 10px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-extra-light);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--blue-medium);
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--blue);
	}
}

.modal {
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4px);
	padding: 15px;

	&-content {
		border: 0;
	}

	&-dialog {
		height: 100%;
		align-items: center;
		display: flex;
		margin: auto;
	}

	&-body {
		padding: 24px;

		h5 {
			margin-bottom: 15px;
		}
	}

	&-footer {
		border: 0;
		padding: 0 24px 24px;

		.btn {
			font-size: 12px;
			height: 24px;

			&.outline {
				border: 0;
			}
		}
	}
}

.cursor-pointer {
	cursor: pointer !important;
}

.check {
	.wrapper {
		h2 {
			text-align: left;
		}
		&-content {
			position: relative;

			@media (min-width: 992px) {
				display: flex;
				gap: 50px;
			}

			@media (min-width: 1282px) {
				gap: 80px;
			}
		}
		h2 {
			font-style: normal;
			font-weight: 600;
			font-size: 22px;
			line-height: 96%;
			color: var(--blue-medium);
			margin-bottom: 20px;
		}

		.devices {
			align-self: baseline;

			&.no-data {
				& > div {
					border-color: var(--yellow);
				}
			}

			& > div {
				color: var(--gray-normal);
				font-style: normal;
				font-weight: 300;
				font-size: 12px;
				line-height: 1.2;
				border: 2px solid var(--gray-normal);
				border-radius: 17px;
				margin-bottom: 15px;
				padding: 10px 25px;
				max-width: 100%;
				min-width: 200px;
				min-height: 45px;
				display: flex;
				align-items: center;
				cursor: pointer;

				&.active {
					background: var(--blue-medium);
					color: var(--white);
					border: 2px solid var(--blue-medium);
				}

				@media (min-width: 992px) {
					min-height: 60px;
					max-width: 320px;
				}

				@media (min-width: 1280px) {
					min-width: 260px;
					max-width: 260px;
				}
			}
		}

		.content {
			max-width: 800px;
			width: 100%;
			background: #fefefe;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			padding: 0 60px;
			align-self: center;

			&.sh-0 {
				box-shadow: none;
			}

			canvas {
				width: 100%;
			}
		}

		&-video {
			video {
				height: 380px;
				width: 100%;
				background: #000;
			}

			canvas {
				position: fixed;
				left: -999rem;
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}
	}
}

.select2 {
	.select2-selection {
		border-radius: 8px;
		border-color: var(--blue);
		padding: 0 30px 0 15px;
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 25px;
		text-transform: uppercase;
		background-repeat: no-repeat;
		background-position-x: calc(100% - 10px);
		background-position-y: 50%;
		background-image: url('./assets/images/icons/arrow-down.svg');
		//max-width: 350px !important;

		&__rendered {
			height: 100%;
			display: flex !important;
			align-items: center;
			font-size: 16px;
			color: var(--blue) !important;
		}

		@media (max-width: 480px) {
			//max-width: 170px !important;

			&__rendered {
				font-size: 11px;
			}
		}
	}

	.select2-selection__arrow {
		display: none;
	}

	.select2-results__option {
		color: var(--blue);
	}

	@media (max-width: 480px) {
		.select2-selection {
			padding: 0 30px 0 0;
		}
	}
}

.select2-container--default
	.select2-results__option--highlighted.select2-results__option--selectable {
	background-color: var(--blue) !important;
}

.select2-container--open .select2-dropdown--below {
	border-color: var(--blue);
}

.select2-results__option--selectable {
	color: var(--blue);
}

.select2-container--default .select2-results > .select2-results__options {
	&::-webkit-scrollbar {
		width: 4px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-extra-light);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--blue-medium);
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--blue);
	}
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border-color: var(--blue);
}

.span-tag {
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    padding: 2px 15px;
    white-space: nowrap;
    margin-left: 10px;
    background: var(--gray-light);
    color: var(--black);

    &.emergency {
        background: var(--red);
        color: var(--white);
    }
    &.very-urgent {
        background: var(--orange);
    }
    &.urgent {
        background: var(--yellow);
    }
    &.little-urgent {
        background: var(--green);
    }
    &.not-urgent {
        background: var(--blue);
        color: var(--white);
    }
}
